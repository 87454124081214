a {
    text-decoration: none;
}

.place:before {
    content: '@';
}

a.instructor {
    color: tint-color($body-color, 15%);
}

.list-group 
{
    > .list-group-header {
        border-color: $body-bg;
        color: #fff;
        text-align: center;

        .subheading {
            color: $body-color;
        }
    }

    .list-group-item {

        .date, .highlight {
            color: #fff;
        }

        dt {
            color: #fff;
        }

        &.list-group-footer {
            padding-bottom: 0px;
            padding-top: 0px;

            .list-group-footer-tab
            {
                border-top: 5px solid $body-bg;
                border-right: 1px solid $body-bg;
                background: $gray-700;
                padding: 0;

                a 
                {
                    padding: 15px;
                    display: block;
                    color: #fff;
                    background-repeat: no-repeat;
                    background-position: center center;

                    &.hide-text
                    {
                        overflow: hidden; 
                        color:transparent;
                    }
                }

                &.danger { 
                border-top-color: $danger;
                }

                &.success { 
                    border-top-color: $success; 
                }

                &.warning { 
                    border-top-color: $warning;
                }

                &.primary { 
                    border-top-color: $primary;
                }
                
                &:hover {
                    background-color: $gray-900;   
                }

                &:first-of-type {
                    border-bottom-left-radius: $border-radius;
                }

                &:last-of-type {
                    border-bottom-right-radius: $border-radius;
                }
            }
        }
    }
}

.navigation-listing {
    .list-group-item {
        padding: 1rem 1.5rem;

        span {
            color: white;
            margin-left: 1rem;
        }
    }
}

.list-group.member-listing
{
    .list-group-item {
        background: $body-bg;

        .label-warning {
            color: #000;
        }
    }
}

.list-group.load-listing {
    div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.profile h5 {
    margin-top: 1em;
}

.typeahead-search-form {
    .twitter-typeahead {
        display: table-cell !important;
        width: 100%;
    }

    .tt-menu {
        background: $gray-800;
        width: 100%;
    }

    .tt-suggestion
    {
        cursor: pointer;
    }    
}

abbr {
    border: none;
    text-decoration: none;
}
