$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$dark: #394264;

$primary: #35aadc;
$success: #11a8ab;
$info: #4fc4f6;
$warning: #fcb150;
$danger: #e64c65;

$body-bg: #1f253d;
$body-color: #9099b7;

$link-color: $info;
$component-active-color: #fff;
$link-hover-decoration: none;

$border-radius: .4rem;

$badge-bg: $gray-700;
$badge-active-bg: $danger;
$badge-font-weight: normal;

$label-default-bg: $gray-700;

$input-color: $white;
$input-bg: #50597b;
$input-border-color: #1f253d;
$input-focus-border-color: #fff;
$input-placeholder-color: $body-color;
$input-focus-border-color: darken($body-bg, 5%);
$input-group-addon-color: $white;
$input-group-addon-bg: #464e6c;

$btn-default-color: $body-color;
$btn-default-bg: $gray-700;
$btn-default-border: $body-bg;

$table-border-color: $body-bg;
$table-bg-hover: $gray-700;
$table-bg-accent: $gray-700;

$table-color: $body-color;
$table-bg: #394264;

$table-dark-color: $body-color;
$table-dark-bg: #181C29;

$list-group-bg: $dark;
$list-group-color: $body-color;
$list-group-hover-bg: #50597a;
$list-group-active-bg: #50597b;
$list-group-border: #1f253d;
$list-group-active-border: $list-group-border;
$list-group-action-color: $body-color;
$list-group-action-hover-color: #fff;
$list-group-action-active-color: $body-color;
$list-group-action-active-bg: #50597a;
